import { Colors, Spacing } from '@walter/shared'
import { ErrorMessage } from '@walter/shared-web'
import { stripUnit } from 'polished'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import ReactSelect, {
  ActionMeta,
  GroupBase,
  InputActionMeta,
  MultiValue,
  SingleValue,
  components,
  createFilter,
} from 'react-select'
import styled from 'styled-components'
import { t } from '../../../utils'
import { Label } from '../../Label'
import { Option } from '../types'
import { setMultiSelectedColor } from './multi-value-styles'

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

type IdObject = {
  id?: string
}

interface SelectProps {
  dataTestId?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  options?: readonly (Option | GroupBase<Option>)[]
  isMulti?: boolean
  defaultValue?: Option | (Option | GroupBase<Option>)[]
  onChange?: (option: any) => void
  className?: string
  name: string
  minWidth?: number
  hint?: string
  additionalOnChange?: (data: any) => void
  errorMessage: string
  isSearchable?: boolean
  onMenuScrollToBottom?: () => void | Promise<void>
  inputValue?: string
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  multiSelectColor?: keyof typeof Colors
  noOptionsText?: string
  menuPlacement?: 'auto' | 'bottom' | 'top'
  matchFrom?: 'start' | 'any'
  multiValueHref?: (item: Option) => string
}

export function Select({
  dataTestId,
  name,
  label,
  minWidth,
  hint,
  placeholder,
  isMulti = false,
  isClearable = false,
  errorMessage,
  defaultValue,
  additionalOnChange,
  disabled,
  isSearchable = true,
  options,
  onMenuScrollToBottom,
  inputValue,
  onInputChange,
  multiSelectColor,
  noOptionsText,
  matchFrom = 'start',
  multiValueHref,
  ...rest
}: SelectProps) {
  const { control } = useFormContext()
  function handleOnChange(onChange: (...event: any[]) => void) {
    return (option: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
      additionalOnChange && additionalOnChange(option)
      if (Array.isArray(option) && isMulti) {
        onChange(
          option?.map((c) => c.value),
          actionMeta,
        )
        return
      }

      if (!option) {
        onChange(null, actionMeta)
        return
      }

      onChange((option as Option).value, actionMeta)
    }
  }

  const defaultfilterOption = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom,
  })

  return (
    <div data-testid={name}>
      {!!label && (
        <Label style={minWidth ? { minWidth: minWidth } : {}}>
          {label}
          {hint && <Hint data-tip={hint}>(?)</Hint>}
        </Label>
      )}
      <div>
        <Controller
          control={control}
          name={name}
          defaultValue={
            isMulti ? (defaultValue as Option[])?.map((option) => option.value) : (defaultValue as Option)?.value
          }
          render={({ field: { onChange, value, name, onBlur, ref } }) => (
            <ReactSelect
              id={dataTestId}
              key={`${name}${value}`}
              classNamePrefix="select"
              classNames={{
                control: () => {
                  return errorMessage ? 'select__control-error' : ''
                },
              }}
              name={name}
              noOptionsMessage={() => noOptionsText ?? t('no-results')}
              loadingMessage={() => t('loading')}
              {...rest}
              isSearchable={isSearchable}
              options={options}
              value={options ? (isMulti ? getMultiValues(options, value) : getSingleValue(options, value)) : undefined}
              getOptionValue={(option) =>
                (option.value as IdObject)?.id ? (option.value as IdObject).id ?? '' : option.value.toString()
              }
              onChange={handleOnChange(onChange)}
              isDisabled={disabled}
              onBlur={onBlur}
              ref={ref}
              isMulti={isMulti}
              isClearable={isClearable}
              placeholder={placeholder && placeholder?.length > 0 ? placeholder : `${t('select')}...`}
              onMenuScrollToBottom={onMenuScrollToBottom}
              menuPlacement="auto"
              styles={
                multiSelectColor
                  ? { ...setMultiSelectedColor(multiSelectColor), menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
                  : { menuPortal: (base) => ({ ...base, zIndex: 9999 }) }
              }
              inputValue={inputValue}
              onInputChange={onInputChange}
              filterOption={defaultfilterOption}
              menuPortalTarget={document.body}
              components={
                multiValueHref
                  ? {
                      MultiValueLabel: (props) => (
                        <Link to={multiValueHref(props.data)} className="hover:underline">
                          <components.MultiValueLabel {...props} />
                        </Link>
                      ),
                    }
                  : undefined
              }
            />
          )}
        />
      </div>

      {errorMessage && <ErrorMessage data-cy="error-message">{errorMessage}</ErrorMessage>}
    </div>
  )
}

function getMultiValues(options: readonly (Option | GroupBase<Option>)[], value: unknown[]) {
  const isGrouped =
    (options as Partial<GroupBase<Option>>[]) != null && !!(options as Partial<GroupBase<Option>>[])[0]?.options

  const allOptions = isGrouped
    ? (options as GroupBase<Option>[]).flatMap((group) => group.options)
    : (options as Option[])

  if (!allOptions) {
    return []
  }

  return allOptions.filter((option) => {
    const optionValue = option.value
    if (typeof optionValue !== 'string' && typeof optionValue !== 'number') {
      return value?.find((v) => (v as { id?: string }).id === optionValue?.id)
    }
    return value?.includes(option.value)
  })
}

function getSingleValue(options: readonly (Option | GroupBase<Option>)[], value: unknown) {
  const isGrouped =
    (options as Partial<GroupBase<Option>>[]) != null && !!(options as Partial<GroupBase<Option>>[])[0]?.options

  const allOptions = isGrouped
    ? (options as GroupBase<Option>[]).flatMap((group) => group.options)
    : (options as Option[])

  if (!allOptions) {
    return null
  }

  return allOptions.find((option) => {
    const optionValue = option.value
    if (typeof optionValue !== 'string' && typeof optionValue !== 'number') {
      return optionValue?.id === (value as { id?: string })?.id
    }
    return optionValue === value || optionValue === (value as { value?: string })?.value
  })
}

import { Notification_Type, Tool_Type } from '../api'
import { getTexts } from './localizations'

export const NOTIFICATION_TYPES: Notification_Type[] = [
  Notification_Type.NewResident,
  Notification_Type.NewChatMessage,
  Notification_Type.NewChatPrivateMentioned,
  Notification_Type.NewReservation,
  Notification_Type.UpdateReservation,
  Notification_Type.NewAd,
  Notification_Type.UpdateAd,
  Notification_Type.TaskAssignee,
  Notification_Type.NewTask,
  Notification_Type.NewCommentSubscribed,
  Notification_Type.NewCommentOnAssigneeTask,
  Notification_Type.NewCommentWithMention,
  Notification_Type.UpdateTask,
  Notification_Type.ResidentSelfRemoval,
  Notification_Type.ThirdPartySynchronizationIssueDuplicatedEmails,
  Notification_Type.EventReminder,
  Notification_Type.TaskDueDateReminder,
]

export const ORDER_NOTIFICATION_TYPES = [
  'ORDER_CHANGE',
  'NEW_ORDER',
  'ORDER_NEW_ADDITIONAL_SERVICE',
  'ORDER_UPDATE_ADDITIONAL_SERVICE',
  'ORDER_STATUS_CHANGED',
]
export const MEMO_NOTIFICATION_TYPES = ['NEW_POST']
export const RULE_NOTIFICATION_TYPES = ['NEW_RULE']
export const FAQ_NOTIFICATION_TYPES = ['NEW_FAQ']
export const AMENITY_NOTIFICATION_TYPES = ['NEW_AMENITY']
export const CONTACT_NOTIFICATION_TYPES = ['NEW_CONTACT']
export const FILE_NOTIFICATION_TYPES = ['NEW_FILE']
export const CALENDAR_NOTIFICATION_TYPES = ['NEW_EVENT']
export const CHAT_NOTIFICATION_TYPES = ['NEW_CHAT_MESSAGE']
export const SALES_NOTIFICATION_TYPES = ['NEW_SALE', 'UPDATE_SALE', 'NEW_AD', 'UPDATE_AD', 'NEW_AD_POSTED']
export const USER_NOTIFICATIONS_TYPES = ['NEW_RESIDENT', 'NEW_RESIDENT_APPROVED']
export const PACKAGE_NOTIFICATIONS_TYPES = ['PACKAGE_AT_RECEPTION', 'PACKAGE_DELIVERED', 'PACKAGE_UPDATE']
export const TASKS_NOTIFICATION_TYPES = [
  'NEW_TASK',
  'UPDATE_TASK',
  'SERVICE_REQUEST_ASSIGNED',
  'NEW_SERVICE_REQUEST',
  'UPDATE_SERVICE_REQUEST',
  'TASK_ASSIGNEE',
  'NEW_COMMENT_ON_ASSIGNEE_TASK',
  'NEW_COMMENT_WITH_MENTION',
  'TASK_DUE_DATE_REMINDER',
]

// TODO: this is used, with both different strings, in manager & provider web
export function getNotificationLabelForType(type: Notification_Type | undefined | null, language: 'en' | 'fr') {
  switch (type) {
    case 'CHAT_NOTIFICATION_REMINDER':
      return getTexts(language, 'chat-notification-reminder')
    case 'NEW_RESIDENT':
      return getTexts(language, 'new-resident')
    case 'NEW_RESIDENT_APPROVED':
      return getTexts(language, 'new-resident-approved')
    case 'NEW_CHAT_MESSAGE':
      return getTexts(language, 'new-chat-message')
    case 'NEW_CHAT_PRIVATE_MENTIONED':
      return getTexts(language, 'new-private-chat-mentioned')
    case 'NEW_POST':
    case 'NEW_PROXY':
      return getTexts(language, 'new-post')
    case 'NEW_PROXY_COMPLETED':
      return getTexts(language, 'new-proxy-completed')
    case 'NEW_EVENT':
      return getTexts(language, 'new-event')
    case 'UPDATE_EVENT':
      return getTexts(language, 'updated-event')
    case 'EVENT_REMINDER':
      return getTexts(language, 'event-reminder')
    case 'NEW_AMENITY':
      return getTexts(language, 'new-amenity')
    case 'NEW_RULE':
      return getTexts(language, 'new-rule')
    case 'NEW_CONTACT':
      return getTexts(language, 'new-contact')
    case 'NEW_FILE':
      return getTexts(language, 'new-file')
    case 'NEW_FOLDER':
      return getTexts(language, 'new-folder')
    case 'NEW_OFFENSE':
      return getTexts(language, 'new-offense')
    case 'UPDATE_OFFENSE':
      return getTexts(language, 'updated-offense')
    case 'PACKAGE_AT_RECEPTION':
      return getTexts(language, 'new-package-at-reception')
    case 'PACKAGE_DELIVERED':
      return getTexts(language, 'package-delivered')
    case 'PACKAGE_UPDATE':
      return getTexts(language, 'package-updated')
    case 'NEW_RESERVATION':
      return getTexts(language, 'new-reservation')
    case 'UPDATE_RESERVATION':
      return getTexts(language, 'reservation-updated')
    case 'NEW_AD':
    case 'NEW_SALE':
      return getTexts(language, 'new-ad-in-the-marketplace')
    case 'UPDATE_AD':
    case 'UPDATE_SALE':
      return getTexts(language, 'update-sale')
    case 'TASK_ASSIGNEE':
      return getTexts(language, 'task-assigned')
    case 'NEW_COMMENT_ON_ASSIGNEE_TASK':
      return getTexts(language, 'new-comment-task-assigned')
    case 'NEW_COMMENT_WITH_MENTION':
      return getTexts(language, 'new-comment-with-mention')
    case 'NEW_COMMENT_SUBSCRIBED':
      return getTexts(language, 'new-comment-on-a-subscribed-task')
    case 'RESIDENT_SELF_REMOVAL':
      return getTexts(language, 'resident-self-removal')
    case 'THIRD_PARTY_SYNCHRONIZATION_ISSUE_DUPLICATED_EMAILS':
      return getTexts(language, 'duplicated-email-in-project')
    case 'TASK_DUE_DATE_REMINDER':
      return getTexts(language, 'task-due-date-reminder')
    default:
  }
}

export function getNotificationsForModuleType<Notification extends { type: string }>(
  notifications: Notification[],
  // 'ORDER' | 'POST' needs to be added in the backend has TOOL_TYPE
  type: Tool_Type | 'ORDER' | 'POST',
) {
  switch (type) {
    case 'CONTACT':
      return notifications.filter(({ type }) => CONTACT_NOTIFICATION_TYPES.includes(type))
    case 'CHAT':
      return notifications.filter(({ type }) => CHAT_NOTIFICATION_TYPES.includes(type))
    case 'CALENDAR':
      return notifications.filter(({ type }) => CALENDAR_NOTIFICATION_TYPES.includes(type))
    case 'ORDER':
      return notifications.filter(({ type }) => ORDER_NOTIFICATION_TYPES.includes(type))
    case 'WORK_ORDER':
      return notifications.filter(({ type }) => TASKS_NOTIFICATION_TYPES.includes(type))
    case 'SALE':
      return notifications.filter(({ type }) => SALES_NOTIFICATION_TYPES.includes(type))
    case 'USER':
      return notifications.filter(({ type }) => USER_NOTIFICATIONS_TYPES.includes(type))
    case 'NOTIFICATION':
    case 'POST':
      return notifications.filter(({ type }) => MEMO_NOTIFICATION_TYPES.includes(type))
    case 'AMENITY':
      return notifications.filter(({ type }) => AMENITY_NOTIFICATION_TYPES.includes(type))
    case 'FILE':
      return notifications.filter(({ type }) => FILE_NOTIFICATION_TYPES.includes(type))
    case 'MAIL':
      return notifications.filter(({ type }) => PACKAGE_NOTIFICATIONS_TYPES.includes(type))
    case 'RULE':
      return notifications.filter(({ type }) => RULE_NOTIFICATION_TYPES.includes(type))
    case 'FAQ':
      return notifications.filter(({ type }) => FAQ_NOTIFICATION_TYPES.includes(type))
    default:
      return []
  }
}

// 'OFFENSE' needs to be put in TOOL_TYPE in the backend
export function getModuleForNotificationType(type: Notification_Type | 'NEW_FAQ'): Tool_Type | 'OFFENSE' | undefined {
  switch (type) {
    case 'CHAT_NOTIFICATION_REMINDER':
    case 'NEW_CHAT_MESSAGE':
      return Tool_Type.Chat
    case 'NEW_RESIDENT':
    case 'NEW_RESIDENT_APPROVED':
      return Tool_Type.User
    case 'NEW_POST':
    case 'NEW_PROXY_COMPLETED':
    case 'NEW_PROXY':
      return Tool_Type.Notification
    case 'NEW_EVENT':
    case 'EVENT_REMINDER':
    case 'UPDATE_EVENT':
      return Tool_Type.Calendar
    case 'NEW_AMENITY':
      return Tool_Type.Amenity
    case 'NEW_RULE':
      return Tool_Type.Rule
    case 'NEW_CONTACT':
      return Tool_Type.Contact
    case 'NEW_FILE':
      return Tool_Type.File
    case 'NEW_OFFENSE':
    case 'UPDATE_OFFENSE':
      return 'OFFENSE'
    case 'PACKAGE_AT_RECEPTION':
    case 'PACKAGE_UPDATE':
    case 'PACKAGE_DELIVERED':
      return Tool_Type.Mail
    case 'NEW_RESERVATION':
    case 'UPDATE_RESERVATION':
      return Tool_Type.Amenity
    case 'NEW_AD':
    case 'NEW_SALE':
    case 'UPDATE_AD':
    case 'UPDATE_SALE':
      return Tool_Type.Sale
    case 'NEW_COMMENT_ON_ASSIGNEE_TASK':
    case 'NEW_COMMENT_WITH_MENTION':
    case 'TASK_ASSIGNEE':
    case 'NEW_TASK':
      return Tool_Type.WorkOrder
    case 'NEW_FAQ':
      return Tool_Type.Faq
  }
}
